<template>
  <div class="ChangePasswordnotmatch">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./ChangePasswordPage" class="page_link">Password Change Page</router-link> > New password and confirm password don't match
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What is "New password and confirm password don't match"?</h1>
      <div class="eximg">
        <div>
          <img alt="change_reset_password_error_notmatch_en" src="../../assets/change_reset_password_error_notmatch_en.png">
        </div>
      </div>
      <h3>Answer</h3>
      <p class="answer">
        The new password(middle green key icon) does not match the confirmation password.<br />
        Please make sure the confirmation password is the same as the new password.
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <router-link to="./ChangePasswordinvalid"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./ChangePasswordsame"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn" /></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

.eximg {
  display: flex;
  flex-direction: row;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
